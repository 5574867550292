<template>
  <v-container fluid class="pa-0">

    <!-- App bar -->
    <v-app-bar height="30" dark color="primary" flat>
      <div class="mx-auto" style="font-size: 20px">Mnemonist Software</div>
    </v-app-bar>

    <!-- Header -->
    <Header :title="$t('events.colors')" :level="level"/>

    <!-- Game -->
    <div id="game" :style="{'width': (2 * tileSize + 50) + 'px', 'height': (2 * tileSize + 50) + 'px'}">
      <div id="red" :style="{'width': tileSize + 'px', 'height': tileSize + 'px'}"
           @click="clickTile('red')">&nbsp;
      </div>
      <div id="green" :style="{'width': tileSize + 'px', 'height': tileSize + 'px'}"
           @click="clickTile('green')">&nbsp;
      </div>
      <div id="blue" :style="{'width': tileSize + 'px', 'height': tileSize + 'px'}"
           @click="clickTile('blue')">&nbsp;
      </div>
      <div id="yellow" :style="{'width': tileSize + 'px', 'height': tileSize + 'px'}"
           @click="clickTile('yellow')">&nbsp;
      </div>
    </div>

  </v-container>
</template>

<script>
import Header from '../components/HeaderWarmUp';

export default {
  name: 'Colors',
  components: {Header},
  data: () => ({
    colors: ['red', 'green', 'blue', 'yellow'],
    level: 0, phase: '', sequence: [], response: []
  }),
  computed: {
    tileSize() {
      return this.$vuetify.breakpoint.width > 500 ? 200 : Math.round((this.$vuetify.breakpoint.width - 100) / 2);
    }
  },
  mounted() {
    this.level = 1;
    this.phase = 'watch';
    this.sequence = [this.colors[Math.floor(Math.random() * 4)]];
    setTimeout(() => {
      this.showSequence();
    }, 1000);
  },
  methods: {
    checkResult() {
      for (let i = 0; i < this.response.length; i++) {
        if (this.sequence[i] !== this.response[i]) {
          this.phase = 'end';
          this.$api.submitGame(0, []);
          let title = this.$t('warm-up-games.congratulations');
          let description = this.$t('warm-up-games.you-achieved-level');
          description = description.toString().replace('{level}', this.level);
          let button = this.$t('common.end');
          let action = () => this.$router.replace('/warm-up/colors');
          let persistent = true;
          this.$store.commit('showSimpleDialog', {title, description, button, action, persistent});
          return;
        }
      }
      if (this.response.length !== this.sequence.length) return;
      setTimeout(() => {
        this.level++;
        this.phase = 'watch';
        let availableColors = this.colors.filter(color => color !== this.sequence[this.sequence.length - 1]);
        this.sequence.push(availableColors[Math.floor(Math.random() * 3)]);
        this.response = [];
        this.showSequence();
      }, 500);
    },
    clickTile(color) {
      if (this.phase !== 'repeat') return;
      this.response.push(color);
      this.checkResult();
      this.highlightTile(color);
    },
    highlightTile(color) {
      document.querySelector('#game #' + color).classList.add('tile-active');
      setTimeout(() => {
        document.querySelector('#game #' + color).classList.remove('tile-active');
      }, 100);
    },
    async showSequence(i = 0) {
      setTimeout(() => {
        this.highlightTile(this.sequence[i]);
        if (i + 1 < this.sequence.length)
          this.showSequence(i + 1);
        else this.phase = 'repeat';
      }, 500);
    }
  }
}
</script>

<style scoped>
#game {
  border: 5px solid #336799;
  border-radius: 10px;
  margin: 10px auto 0 auto;
  position: relative;
}

#red, #green, #blue, #yellow {
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  transition-duration: 100ms;
  user-select: none;
}

/*noinspection CssUnusedSymbol*/
.tile-active {
  transform: scale(1.05);
}

#red {
  background-color: #F44336;
  left: 15px;
  top: 15px;
}

#red:hover {
  background-color: #D32F2F;
}

#green {
  background-color: #4CAF50;
  right: 15px;
  top: 15px;
}

#green:hover {
  background-color: #388E3C;
}

#blue {
  background-color: #2196F3;
  bottom: 15px;
  left: 15px;
}

#blue:hover {
  background-color: #1976D2;
}

#yellow {
  background-color: #FFC107;
  bottom: 15px;
  right: 15px;
}

#yellow:hover {
  background-color: #FFB300;
}
</style>
